import React, { useState } from 'react';
import { supabase } from '../supabaseClient';

const Register = ({ onClose }) => {
  // const [user, setUser] = useState(null); // State to store user information
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    username: '',
    instansi: '',
  });
  const [errorMessage, setErrorMessage] = useState('');

  const handleRegister = async (e) => {
    e.preventDefault();
    setErrorMessage(''); // Bersihkan pesan error sebelumnya

    // Mendaftar pengguna ke Supabase Auth
    const { user, error: authError } = await supabase.auth.signUp({
      email: formData.email,
      password: formData.password,
    });

    if (authError) {
      setErrorMessage(authError.message);
      return;
    }

    const { data: { session } } = await supabase.auth.getSession();
    const currentUser = session?.user || null;

    // Setelah pengguna berhasil mendaftar, masukkan data ke tabel 'users'
    const { error: dbError } = await supabase
      .from('users') // Pastikan 'users' adalah nama tabel yang benar
      .insert([
        {
          id: currentUser.id, // Menggunakan auth_id sebagai foreign key
          nama_lengkap: formData.username, // Username dari form
          email: formData.email, // Email yang terdaftar di Auth
          instansi: formData.instansi, // Data instansi dari form
          role_id: 2, // Default role_id untuk pengguna baru
        },
      ]);


    if (dbError) {
      setErrorMessage(dbError.message);
      return;
    }

    // Jika sukses, tutup modal dan beri tahu pengguna
    onClose();
    alert('Registrasi berhasil!');
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">

      {/* Modal Content */}
      <div className="relative bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 z-10 max-w-lg w-full mx-4">
        <button
          onClick={onClose}
          className="absolute top-0 right-0 m-4 text-gray-600 text-2xl hover:text-gray-800"
        >
          &times;
        </button>

        <form onSubmit={handleRegister} className="w-full">
          <h2 className="text-2xl font-semibold mb-4 text-gray-800">Daftar</h2>

          {errorMessage && <p className="text-red-500 mb-4">{errorMessage}</p>}

          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">Username</label>
            <input
              type="text"
              name="username"
              value={formData.username}
              onChange={handleChange}
              required
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Input nama lengkap"
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">Instansi</label>
            <input
              type="text"
              name="instansi"
              value={formData.instansi}
              onChange={handleChange}
              required
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Input asal instansi"
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">Email</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Input alamat email"
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">Password</label>
            <input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Input kata sandi"
            />
          </div>

          <div className="flex items-center justify-between">
            <button
              type="submit"
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Daftar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Register;
