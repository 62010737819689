// src/pages/LayananInovasi.js

import React from 'react';
import VideoSection from '../components/VideoSection'



const LayananInovasi = () => {
  return (
    <div>
     
    <VideoSection />
    </div>
  );
};

export default LayananInovasi;
